import { Field } from "formik";
import * as React from "react";
import { ReactStripeElements } from "react-stripe-elements";
import { arrayEmptyOrNotExist } from "../../../common/helper/helper";
import { FormConstants } from "../../GenericForm/Components/Constants";
import CheckoutFactory from "./CheckoutFactory";
import FormField from "./FormField";
import { PaypalButton } from "./PaypalButton";
import { createCurrencySign } from "./Step0";

interface IStep2Props {
	animClass: string;
	values: any;
	setFieldValue: any;
	setPaymentInputComplete: any;
	colorTheme: string;
	isColumn: boolean;
	paymentProvider: string;
	contentId: string;
	setSubmitError: any;
	stripe: ReactStripeElements.StripeProps;
	updateTokenGenerator: any;
	errors: {};
	touched: {};
	donationRecipients: string[];
	deafaultDonateDes: string;
	rootNode: string;
	setExpiryMonth: (expiryMonth: string) => void;
	setExpiryYear: (expiryYear: string) => void;
	campaigns?: JSX.Element[];
	intervalOptions?: paymentInterval[];
	currency: string;
	recurringDonationOptionsOnFirstPanel: boolean;
	setSubmitting: (isSubmitting: boolean) => void;
	submitForm: () => void;
	paypalClientId?: string;
    isSubmitting: boolean;
    stepNum: number;
}

export type paymentInterval = "One-off" | "Weekly" | "Monthly";

const DonateFormStepTwo: React.FC<IStep2Props> = (props) => {
	return (
		<div className={"container donStep2"}>
			<div className="donFields row justify-content-center">
				{props.campaigns && props.campaigns.length > 0 && (
					<div>
						<p className={"donCauseText"}>
							I wish to donate to the following campaign:
						</p>
						<FormField
							colorTheme={props.colorTheme}
							isColumn={props.isColumn}
							type="select"
							className="form-control standard-form-group"
							name={FormConstants.donationRecipient}
							component="select"
							placeholder="Campagin*"
							errors={props.errors}
							touched={props.touched}
							children={props.campaigns}
						/>
					</div>
				)}
				{!props.recurringDonationOptionsOnFirstPanel && (
					<div className="donCenterRadio">
						<Field
							className={"donFrequency donOpposite" + props.colorTheme}
							component="div"
							name={FormConstants.interval}
						>
							{(arrayEmptyOrNotExist(props.intervalOptions) ||
								props.intervalOptions.includes("One-off")) && (
								<div className="donRadioWrapper form-check">
									<input
										defaultChecked={
											props.values[FormConstants.interval] === "one"
										}
										name={FormConstants.interval}
										type="radio"
										value="one"
										className="form-check-input"
										id="one"
									/>
									<label className="form-check-label" htmlFor="oneOff">
										One-Off
									</label>
								</div>
							)}
							{(arrayEmptyOrNotExist(props.intervalOptions) ||
								props.intervalOptions.includes("Weekly")) && (
								<div className="donRadioWrapper form-check">
									<input
										defaultChecked={
											props.values[FormConstants.interval] === "week"
										}
										name={FormConstants.interval}
										type="radio"
										value="week"
										className="form-check-input"
										id="week"
									/>
									<label className="form-check-label" htmlFor="week">
										Weekly
									</label>
								</div>
							)}
							{(arrayEmptyOrNotExist(props.intervalOptions) ||
								props.intervalOptions.includes("Monthly")) && (
								<div className="donRadioWrapper form-check">
									<input
										defaultChecked={
											props.values[FormConstants.interval] === "month"
										}
										name={FormConstants.interval}
										type="radio"
										value="month"
										className="form-check-input"
										id="month"
									/>
									<label className="form-check-label" htmlFor="month">
										Monthly
									</label>
								</div>
							)}
						</Field>
					</div>
				)}
			</div>
			<p className={"donConfirmAmount donOpposite" + props.colorTheme}>
				{createCurrencySign(props?.currency)}
				{props.values[FormConstants.amount] &&
					Number(props.values[FormConstants.amount])
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
			</p>
			<p className={"donTaxMessage donOpposite" + props.colorTheme}>
				Contributions are tax deductible.
			</p>
			<div className="row justify-content-center">
				<CheckoutFactory
					provider={props.paymentProvider}
					setComplete={props.setPaymentInputComplete}
					colorTheme={props.colorTheme}
					values={props.values}
					contentId={props.contentId}
					setSubmitError={props.setSubmitError}
					stripe={props.stripe}
					updateTokenGenerator={props.updateTokenGenerator}
					setExpiryMonth={props.setExpiryMonth}
					setExpiryYear={props.setExpiryYear}
				/>
			</div>
			{props.values[FormConstants.amount] && props.stepNum === 2 &&
				props.paypalClientId !== undefined &&
				props.paypalClientId !== "" && (
					<PaypalButton 
                        contendId={props.contentId}
                        colorTheme={props.colorTheme}
                        paymentProvider={props.paymentProvider}
                        setFieldValue={props.setFieldValue}
                        setSubmitError={props.setSubmitError}
                        submitForm={props.submitForm}
                        values={props.values}
                        paypalClientId={props.paypalClientId}
                        isSubmitting={props.isSubmitting}
                    />
				)}
		</div>
	);
};

export default DonateFormStepTwo;
