import * as React from "react";

import { Form, FormikErrors, FormikProps, FormikTouched } from "formik";
import "../Style/DonateForm.scss";
import ProgressBar from "./ProgressBar";
import Step0 from "./Step0";
import Step1, { stepOneAllFields, stepOneStandardFields } from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import {
	DonateFormContactDetailsCheck,
	FormConstants,
	IDonateFormProps,
} from "../../GenericForm/Components/Constants";
import ErrorMessage from "./ErrorMessage";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";
import { IFieldProps } from "../../GenericForm/Components/FormFields";
import Checkbox from "formik-antd/es/checkbox";
import { DonateFormModel } from "../../ml_modules/websiteApi/DonateFormModel";

interface IInnerFormProps {
	stepNum: number;
	stepImmediateNum: number;
	setStepNum: (num: number) => void;
	setSubmitDisabled: (disabled: boolean) => void;
	setSubmitClass: (submitClass: string) => void;
	setContinueDisabled: (disabled: boolean) => void;
	setContinueClass: (continueClass: string) => void;
	setBackDisabled: (disabled: boolean) => void;
	setBackClass: (backClass: string) => void;
	setSubmitError: (submitError: string) => void;
	buttonAmountOnClick: (amount: number, setFieldValue: any) => void;
	updateTokenGenerator: (func: () => Promise<any>) => void;
	setPaymentInputComplete: (complete: boolean) => void;
	setExpiryMonth: (expiryMonth: string) => void;
	setExpiryYear: (expiryYear: string) => void;
	setDeclarationAccepted: (declarationAccepted: boolean) => void;
	declarationAccepted: boolean;
	step0Class: string;
	step1Class: string;
	step2Class: string;
	step3Class: string;
	backClass: string;
	continueClass: string;
	submitError: string;
	submitClass: string;
	backDisabled: boolean;
	continueDisabled: boolean;
	submitDisabled: boolean;
	rootNode: string;
	campaigns?: JSX.Element[];
	autoCompleted: boolean;
	setAutoCompleted: (autoCompleted: boolean) => void;
	donorData: DonateFormContactDetailsCheck;
	setDonorData: (donorData: DonateFormContactDetailsCheck) => void;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const continueOnClick = (
	errors: FormikErrors<IDonateFormProps>,
	touched: FormikTouched<IDonateFormProps>,
	values: IDonateFormProps,
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
	stepNum: number,
	setStepNum: (step: number) => void,
	enableCountryField: boolean,
	customFields: IFieldProps[]
) => {
	const stepOneFields = [
		...(enableCountryField ? stepOneAllFields : stepOneStandardFields),
		...customFields?.map((field) => field.Name),
	];
	if (stepNum < 3) {
		if (
			stepNum === 0 &&
			!errors[FormConstants.amount] &&
			(touched[FormConstants.amount] || values[FormConstants.amount])
		) {
			setStepNum(stepNum + 1);
		} else if (
			stepNum === 1 &&
			!stepOneFields.some((field) => errors[field]) &&
			stepOneFields.every((field) => touched[field])
		) {
			setStepNum(stepNum + 1);
		} else if (stepNum !== 0) {
			setFieldTouched(FormConstants.firstName, true, true);
			setFieldTouched(FormConstants.lastName, true, true);
			setFieldTouched(FormConstants.address, true, true);
			setFieldTouched(FormConstants.suburb, true, true);
			setFieldTouched(FormConstants.postcode, true, true);
			setFieldTouched(FormConstants.phone, true, true);
			setFieldTouched(FormConstants.email, true, true);
			setFieldTouched(FormConstants.state, true, true);
			customFields?.forEach((field) => setFieldTouched(field.Name, true, true));
			if (enableCountryField) {
				setFieldTouched(FormConstants.country, true, true);
			}
		}
	}
};

const InnerForm: React.FC<IDonateFormProps & FormikProps<IDonateFormProps> & IInnerFormProps> = (
	props
) => {
	const getStyle = () => {
		const style = {} as React.CSSProperties;
		if (props.landingPage !== undefined && props.landingPage) {
			style.background = "transparent";
		}
		if (props.narrowForm && !props.landingPage) {
			style.width = "400px";
			style.maxWidth = "100%";
		}
		return style;
	};

	React.useEffect(() => {
		if (
			(props.values[FormConstants.amount] &&
				props.values[FormConstants.amount] !== 0 &&
				!isNullOrWhiteSpace(props.values[FormConstants.amount])) ||
			props.stepNum === 1
		) {
			props.setContinueDisabled(false);
			props.setContinueClass(props.colorTheme + "HoverButton");
		}
		if (
			!props.values[FormConstants.amount] ||
			props.values[FormConstants.amount] == 0 ||
			isNullOrWhiteSpace(props.values[FormConstants.amount])
		) {
			props.setContinueDisabled(true);
		}
		if (
			props.donationDeclaration &&
			props.values[FormConstants.donationDeclaration] === true &&
			props.declarationAccepted === false
		) {
			props.setDeclarationAccepted(true);
		} else {
			if (props.declarationAccepted === true) props.setDeclarationAccepted(false);
		}
	}, [props.values]);
	React.useEffect(() => {
		if (props.isSubmitting) {
			props.setSubmitDisabled(true);
			props.setSubmitClass("");
			props.setBackDisabled(true);
			props.setBackClass("");
		}
	}, [props.isSubmitting]);
	return (
		<Form>
			<div
				className={
					`${props.disableGradient ? "" : "donGradient"} donForm ` + props.colorTheme
				}
				style={getStyle()}
			>
				<div className="contentWrapper">
					<div className="formLeftSide">
						<div>
							<p
								className={"donFormHeader donOpposite" + props.colorTheme}
								style={{ visibility: props.stepNum === 3 ? "hidden" : "visible" }}
							>
								{props.formHeader}
							</p>
							{props.formHeaderSubtext && (
								<p
									className={
										"donFormHeaderSubtext donOpposite" + props.colorTheme
									}
									style={{
										visibility: props.stepNum === 3 ? "hidden" : "visible",
									}}
								>
									{props.formHeaderSubtext}
								</p>
							)}
							<hr className="donLine" />
							<div className="rootContainerThing">
								<div className={props.step0Class}>
									<Step0
										currency={props.defaultCurrency}
										animClass={props.step0Class}
										setFieldValue={props.setFieldValue}
										donateAmounts={props.donateAmounts}
										values={props.values}
										onClick={props.buttonAmountOnClick}
										colorTheme={props.colorTheme}
										isColumn={props.fieldsAsColumn || props.narrowForm}
										buttonRoundedCorners={props.buttonRoundedCorners}
										hideOtherAmount={props.hideOtherAmount}
										recurringDonationOptionsOnFirstPanel={
											props.recurringDonationOptionsOnFirstPanel
										}
										intervalOptions={props.intervalOptions}
									/>
								</div>
								<div className={props.step1Class}>
									{props.stepImmediateNum == 1 && (
										<Step1
											animClass={props.step1Class}
											errors={props.errors}
											touched={props.touched}
											setFieldValue={props.setFieldValue}
											values={props.values}
											colorTheme={props.colorTheme}
											isColumn={props.fieldsAsColumn || props.narrowForm}
											enableCountryField={props.enableCountryField}
											customFields={props.customFields}
											findDonorByEmailaddress={props.findDonorByEmailaddress}
											autoCompleted={props.autoCompleted}
											setAutoCompleted={props.setAutoCompleted}
											donorData={props.donorData}
											setDonorData={props.setDonorData}
											setFieldTouched={props.setFieldTouched}
										/>
									)}
								</div>
								<div className={props.step2Class}>
									<Step2
										animClass={props.step2Class}
										values={props.values}
										setFieldValue={props.setFieldValue}
										setPaymentInputComplete={props.setPaymentInputComplete}
										colorTheme={props.colorTheme}
										isColumn={props.fieldsAsColumn || props.narrowForm}
										setSubmitError={props.setSubmitError}
										paymentProvider={props.paymentProvider}
										contentId={props.contentId}
										stripe={props.stripe}
										updateTokenGenerator={props.updateTokenGenerator}
										donationRecipients={props.donationRecipients}
										deafaultDonateDes={props.defaultDonateDes}
										errors={props.errors}
										touched={props.touched}
										rootNode={props.rootNode}
										setExpiryMonth={props.setExpiryMonth}
										setExpiryYear={props.setExpiryYear}
										campaigns={props.campaigns}
										intervalOptions={props.intervalOptions}
										currency={props?.defaultCurrency}
										recurringDonationOptionsOnFirstPanel={
											props.recurringDonationOptionsOnFirstPanel
										}
										setSubmitting={props.setSubmitting}
										submitForm={props.submitForm}
										paypalClientId={props.paypalClientId}
										isSubmitting={props.isSubmitting}
										stepNum={props.stepNum}
									/>
								</div>
								<div className={props.step3Class}>
									<Step3
										amount={Number(props.values[FormConstants.amount])}
										tYMessage={props.tYMessage}
										shareUrl={props.shareUrl}
										shareTitle={props.shareTitle}
										shareMessage={props.shareMessage}
										shareHashTag={props.shareHashtag}
										colorTheme={props.colorTheme}
										isColumn={props.fieldsAsColumn || props.narrowForm}
										currency={props?.defaultCurrency}
									/>
								</div>
							</div>
						</div>
						<div className="donProgressArea container">
							<div className="donSubmitErrorMessage">{props.submitError}</div>
							<ProgressBar stepNumber={props.stepNum} colorTheme={props.colorTheme} />
							<div className="donErrorMessages">
								{Object.keys(props.values).map((key, index) => {
									if (props.touched[key] && props.errors[key]) {
										return (
											<ErrorMessage
												errors={props.errors}
												touched={props.touched}
												colorTheme={props.colorTheme}
												name={key}
												key={index}
											/>
										);
									}
								})}
							</div>
							{props.stepNum === 2 && props.donationDeclaration ? (
								<div className="container">
									<div
										style={{ textAlign: "center" }}
										className={`checkbox-group-wrapper my-2 px-4 col-md-12 ${
											props.touched[FormConstants.donationDeclaration] &&
											props.errors[FormConstants.donationDeclaration]
												? "error-validation-custom error-validation-" +
												  props.colorTheme
												: ""
										}`}
									>
										<Checkbox name={FormConstants.donationDeclaration}>
											{props.donationDeclaration}
										</Checkbox>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="progress-bar-buttons">
								{props.stepNum > 0 && (
									<button
										className={`donButton ${props.colorTheme}ContSubButton ${
											props.backClass
										} ${
											props.buttonRoundedCorners
												? "donButtonRoundedCorners"
												: ""
										}`}
										type="button"
										style={{
											visibility: props.stepNum === 3 ? "hidden" : "visible",
										}}
										disabled={props.backDisabled}
										onClick={() => {
											if (props.stepNum > 0) {
												{
													props.setStepNum(props.stepNum - 1);
												}
											}
										}}
									>
										Back
									</button>
								)}
								{props.stepNum < 2 && (
									<button
										className={`donButton ${props.colorTheme}ContSubButton ${
											props.continueClass
										} ${
											props.buttonRoundedCorners
												? "donButtonRoundedCorners"
												: ""
										}`}
										type="button"
										onClick={() =>
											continueOnClick(
												props.errors,
												props.touched,
												props.values,
												props.setFieldTouched,
												props.stepNum,
												props.setStepNum,
												props.enableCountryField,
												props.customFields
											)
										}
										disabled={props.continueDisabled}
									>
										Continue
									</button>
								)}
								{props.stepNum === 2 && (
									<button
										className={`donButton ${props.colorTheme}ContSubButton ${
											props.submitClass
										} ${
											props.buttonRoundedCorners
												? "donButtonRoundedCorners"
												: ""
										}`}
										type="submit"
										disabled={props.submitDisabled}
									>
										Donate
									</button>
								)}
							</div>
						</div>
					</div>
					{props.formImageUrl && !props.narrowForm && (
						<div className="donImageWrapper">
							<img className="donImage img-fluid p-0" src={props.formImageUrl}></img>
						</div>
					)}
				</div>
			</div>
		</Form>
	);
};

export default InnerForm;
