

import * as moment from 'moment';
import { Dat } from './EventDataFormat';


declare var google: any;


export const getCCEventsPage = async(contentId:number, pageNum: number, recordsPerPage: number, lat: number|null, lng: number|null, updateData: (data:any) =>void, updateLoadingState: (state: boolean) => void, map?: any, ) => {
        if(updateLoadingState!= null){
            updateLoadingState(true)
        }
        const url = `/Umbraco/Api/Event/GetCCEventsPage?contentId=${contentId}&pageNumber=${pageNum}&recordsPerPage=${recordsPerPage}&lat=${lat}&lng=${lng}`;
        const res = await fetch(url, {method: "GET",})
            .then((resp) => resp.json())
            .then(function(data) {

                var ccEventsAll = data[Dat.CCEventsAll];
                var ccEventsPaged = data[Dat.CCEventsPaged];

                if (data && ccEventsAll && map) {
                    var bounds = new google.maps.LatLngBounds();
                    ccEventsAll.forEach(function (event: any) {
                       var local = moment(event.StartDate).format('DD MMM YYYY hh:mm a');;

                        var contentString = `
                        <a class="ml-infoWindow" href="${event.ContentUrl}"
                            <h4>${event.Name ? event.Name : ""}</h4>
                            <div>${local != null ? local : ""}</div>
                            <div>${event.Location != null ? event.Location : ""}</div>
                            <div>${event.Description != null ? event.Description : ""}</div>
                        </a>`;

                        var infowindow = new google.maps.InfoWindow({
                            content: contentString
                        });

                        var latLng = { lat: event.Latitude, lng: event.Longitude };

                        var marker = new google.maps.Marker({
                            position: latLng,
                            map: map,
                            title: event.Name
                        });
                        marker.addListener('click', function () {
                            infowindow.open(map, marker);
                        });
                        
                        bounds.extend(marker.position);
                    });
                    map.fitBounds(bounds);
                }
                
                return data
            })
        
        updateData(res)
        if(updateLoadingState != null){
            updateLoadingState(false)
        }
}

export const getBoundedEventsPage = async (contentId: number, pageNum: number, recordsPerPage: number, northEastLat: number, northEastLng: number, southWestLat: number, southWestLng: number,
    updateData: (data: any) => void, updateLoadingState:(state: boolean)=>void, updateError:(errorMeg: string, errorState: boolean)=>void, map: any) => {
    if(updateLoadingState != null){
        updateLoadingState(true)
    }    
    const url = `/Umbraco/Api/Event/GetBoundedEvents?contentId=${contentId}&pageNumber=${pageNum}&recordsPerPage=${recordsPerPage}&northEastLat=${northEastLat}&northEastLng=${northEastLng}&southWestLat=${southWestLat}&southWestLng=${southWestLng}`;
    const res = await fetch(url)
        .then((resp) => resp.json())
        .then((data) => {
            var ccEventsAll = data[Dat.CCEventsAll];
            var ccEventsPaged = data[Dat.CCEventsPaged];

            if (data && ccEventsAll.length > 0 && map) {

                ccEventsAll.forEach(function (event: any) {
                    var local = moment(event.StartDate).format('DD MMM YYYY hh:mm a');;

                    var contentString = `
                    <a class="ml-infoWindow" href="${event.ContentUrl}"
                        <h4>${event.Name ? event.Name : ""}</h4>
                        <div>${local != null ? local : ""}</div>
                        <div>${event.Location != null ? event.Location : ""}</div>
                        <div>${event.Description != null ? event.Description : ""}</div>
                    </a>`;

                    var infowindow = new google.maps.InfoWindow({
                        content: contentString
                    });

                    var latLng = { lat: event.Latitude, lng: event.Longitude };

                    var marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        title: event.Name
                    });

                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });
                });
            }
            return data;
        })
    if(updateLoadingState != null){
        updateLoadingState(false)
    } 
    if(res == null){
        updateError("Sorry, there are no current events available in the area you are viewing. Please zoom out to see more events.", true);
    } else {
        updateError("", false)
    }
    updateData(res);
}

export const getPostCodeBounds = async(contentId: number,postCode: string, recordsPerPage:number, callback: (data:any)=>void, 
                updateLoading: (state:boolean)=>void, updateError: (errorMsg: string, errorState: boolean) => void, map?: any, ) => {
    
    const url = `/Umbraco/Api/Event/GetPostCodeBounds?postCode=${postCode}`;
    if(map!= null){
    fetch(url)
        .then((resp) => resp.json())
        .then(function(data){
            map.fitBounds(data);
        })
    }else{
        const res = await fetch(url)
        .then((resp) => resp.json())
        .then((data)=>data)
        const northEastLat = res.south;
        const northEastLng = res.east;
        const southWestLat = res.north;
        const southWestLng = res.west;
        getBoundedEventsPage(contentId, 0, recordsPerPage,northEastLat, northEastLng,southWestLat,southWestLng,callback, updateLoading, updateError, map)
    }

}

