import {
	PayPalButtons,
	PayPalScriptProvider,
	usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { FormConstants } from "../../../components/GenericForm/Components/Constants";
import React, { useEffect } from "react";

interface IPaypalButtonProps {
	values: any;
	setFieldValue: any;
	colorTheme: string;
	paymentProvider: string;
	setSubmitError: any;
	submitForm: () => void;
	paypalClientId?: string;
	isSubmitting: boolean;
	contendId: string;
}

const InnerPaypalButton = (props: IPaypalButtonProps) => {
	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
		if (props.values[FormConstants.interval] === "one") {
			dispatch({
				type: "resetOptions",
				value: {
					...options,
					vault: false,
					intent: "capture",
				},
			});
		} else {
			dispatch({
				type: "resetOptions",
				value: {
					...options,
					vault: true,
					intent: "subscription",
				},
			});
		}
	}, [props.values[FormConstants.interval]]);
	return (
		<div className="container justify-content-center" style={{ marginTop: 10, textAlign: 'center' }}>
			<p
				className={"donTaxMessage donOpposite" + props.colorTheme}
				style={{ marginBottom: 10, marginTop: 10 }}
			>
				Or use:
			</p>

			{props.values[FormConstants.interval] === "one" ? (
				<PayPalButtons
					style={{ layout: "horizontal", tagline: false }}
					onApprove={(data, actions) => {
						return actions.order.capture().then((orderData) => {
							if (orderData.status === "COMPLETED") {
								props.setFieldValue(
									FormConstants.paypalTransactionId,
									orderData.purchase_units[0].payments.captures[0].id
								);
								props.submitForm();
							}
						});
					}}
					onCancel={() => {
						props.setFieldValue("PaymentProvider", props.paymentProvider);
					}}
					onError={(error) => {
						props.setFieldValue("PaymentProvider", props.paymentProvider);
						props.setSubmitError(error.message as string);
					}}
					onClick={(data, actions) => {
						props.setFieldValue("PaymentProvider", "paypal");
					}}
					createOrder={(data, actions) => {
						return actions.order.create({
							intent: "CAPTURE",
							purchase_units: [
								{
									amount: {
										value: Number(props.values[FormConstants.amount])
											.toFixed(2)
											.toString(),
										currency_code: "AUD",
									},
									description: "Donation",
								},
							],
						});
					}}
				/>
			) : (
				<PayPalButtons
					style={{ layout: "horizontal", tagline: false }}
					onCancel={() => {
						props.setFieldValue("PaymentProvider", props.paymentProvider);
					}}
					onError={(error) => {
						props.setFieldValue("PaymentProvider", props.paymentProvider);
						props.setSubmitError(error.message as string);
					}}
					onClick={(data, actions) => {
						props.setFieldValue("PaymentProvider", "paypal");
					}}
					createSubscription={async (data, actions) => {
						const body = {
							interval: props.values[FormConstants.interval],
							amount: Number(props.values[FormConstants.amount]),
							contentId: props.contendId,
						};
						return await fetch("/Umbraco/Api/Paypal/PaypalPlanId", {
							method: "POST",
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								...body,
							}),
						}).then(async (resp) => {
							const response = await resp.json();
							if (response.PlanId) {
								return actions.subscription.create({
									plan_id: response.PlanId,
								});
							}
						});
					}}
					onApprove={(data, actions: any) => {
						return actions.subscription.get().then((subscription) => {
							props.setFieldValue(FormConstants.paypalSubscriptionId, subscription.id);
							props.submitForm();
						});
					}}
				/>
			)}
		</div>
	);
};

export const PaypalButton = (props: IPaypalButtonProps) => {
	return (
		<PayPalScriptProvider
			options={{
				"client-id": props.paypalClientId,
				currency: "AUD",
				vault: false,
				intent: "capture",
			}}
		>
			<InnerPaypalButton {...props} />
		</PayPalScriptProvider>
	);
};
