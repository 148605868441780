export var hideFields = [] as any[];

//We expect Umbraco checkbox editor to pass in PascalCase strings to match the FormData Pascal Case input names, but strip space first.
export const setHideFields = (toSet: string[]) => {
    if (toSet && toSet.length > 0) {

        hideFields = toSet.map(str => str.replace(/ /g, ''));
    }
}

export const Dat = {
    FormName: "CCEventsListForm",
    Display: {
        FormErrors: "FormErrors",
        SubmittedReceipt: "SubmittedReceipt",

    },
    Modal: {
        ShowPayment: "ShowPaymentModal",
        ShowErrorModal: "ShowErrorModal",
        ShowSuccessModal: "ShowSuccessModal",
        ShowConfirmModal: "ShowConfirmModal",

    },

    Options: {
    },

    Loader: {
        DisableForm: "DisableForm"
    },

    Search: {
        Lat: "lat",
        Lng: "lng"
    },

    Page: {
        PageNum: "pageNum",
        RecordsPerPage: "recordsPerPage",
        PageCount: "pageCount"
    },


    CCEventsPaged: "ccEventsPaged",
    CCEventsAll: "ccEventsAll",

    EventId: "eventId",
    ContentId: "contentId",
    Bounds: {
        north: 0,
        south: 0,
        east: 0,
        west: 0 
    }

}

//Redux form formvalue selected values defined here.
export const ToSelect =
    [
        Dat.Display.FormErrors,
        Dat.Display.SubmittedReceipt,
        Dat.Loader.DisableForm,
        Dat.Modal.ShowSuccessModal,
        Dat.Modal.ShowErrorModal,
        Dat.Modal.ShowConfirmModal,
        Dat.CCEventsAll

    ];

export const InitValues = {
    ShowSuccessModal: false
};