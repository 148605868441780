import { Field, Form, FormikProps } from "formik";
import Checkbox from "formik-antd/es/checkbox";
import * as React from "react";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon,
	RedditShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";
import { ClipLoader } from "react-spinners";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { useDebounce } from "../../../common/debounce";
import {
	DonateFormContactDetailsCheck,
	FormConstants,
	IDonateFormProps,
	REPLACE
} from "../../GenericForm/Components/Constants";
import { formatNumber } from "../../GenericForm/Components/FunctionLib";
import { CustomFormFieldFactory } from "../Components/FormField";
import "../Style/DonateForm.scss";
import CheckoutFactory from "./CheckoutFactory";
import ErrorMessage from "./ErrorMessage";
import FormField from "./FormField";
import { getFormDataByEmailaddress } from "./getFormDataByEmailaddress";
import { PaypalButton } from "./PaypalButton";
import { createCurrencySign } from "./Step0";
import { stepOneAllFields, StepOneFieldFactory, stepOneStandardFields } from "./Step1";

interface IInnerFormProps {
	setSubmitDisabled: (disabled: boolean) => void;
	setSubmitClass: (submitClass: string) => void;
	setSubmitError: (submitError: string) => void;
	updateTokenGenerator: (func: () => Promise<any>) => void;
	setPaymentInputComplete: (complete: boolean) => void;
	setExpiryMonth: (expiryMonth: string) => void;
	setExpiryYear: (expiryYear: string) => void;
	setDeclarationAccepted: (declarationAccepted: boolean) => void;
	autoCompleted: boolean;
	setAutoCompleted: (autoCompleted: boolean) => void;
	donorData: DonateFormContactDetailsCheck;
	setDonorData: (donorData: DonateFormContactDetailsCheck) => void;
	declarationAccepted: boolean;
	submitError: string;
	submitClass: string;
	submitDisabled: boolean;
	rootNode: string;
	campaigns?: JSX.Element[];
	stepNum: number;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const SinglePageInnerForm: React.FC<
	IDonateFormProps & FormikProps<IDonateFormProps> & IInnerFormProps
> = (props) => {
	const getStyle = () => {
		const style = {} as React.CSSProperties;
		if (props.landingPage !== undefined && props.landingPage) {
			style.background = "transparent";
		}
		if (props.narrowForm && !props.landingPage) {
			style.width = "400px";
			style.maxWidth = "100%";
		}
		return style;
	};
	React.useEffect(() => {
		if (props.isSubmitting) {
			props.setSubmitDisabled(true);
			props.setSubmitClass("");
		}
	}, [props.isSubmitting]);

	React.useEffect(() => {
		if (
			props.donationDeclaration &&
			props.values[FormConstants.donationDeclaration] === true &&
			props.declarationAccepted === false
		) {
			props.setDeclarationAccepted(true);
		} else {
			if (props.declarationAccepted === true) props.setDeclarationAccepted(false);
		}
	}, [props.values]);

	const [isFetchingDonorData, setIsFetchingDonorData] = useState<boolean>(false);

	const debouncedEmailAddress = useDebounce(props.values[FormConstants.email], 2000);

	useEffect(() => {
		const fetchDonorData = async () => {
			await getFormDataByEmailaddress(debouncedEmailAddress).then(
				(data: DonateFormContactDetailsCheck) => {
					setIsFetchingDonorData(false);
					if (data !== null) {
						if (props.autoCompleted === false) {
							props.setAutoCompleted(true);
						}
						if (data?.Address === true && props.values[FormConstants.address] === "") {
							props.setFieldValue(FormConstants.address, REPLACE);
							props.setFieldTouched(FormConstants.address, true, true);
						}
						if (
							data?.FirstName === true &&
							props.values[FormConstants.firstName] === ""
						) {
							props.setFieldValue(FormConstants.firstName, REPLACE);
							props.setFieldTouched(FormConstants.firstName, true, true);
						}
						if (
							data?.LastName === true &&
							props.values[FormConstants.lastName] === ""
						) {
							props.setFieldValue(FormConstants.lastName, REPLACE);
							props.setFieldTouched(FormConstants.lastName, true, true);
						}
						if (data?.Phone === true && props.values[FormConstants.phone] === "") {
							props.setFieldValue(FormConstants.phone, REPLACE);
							props.setFieldTouched(FormConstants.phone, true, true);
						}
						if (
							data?.Postcode === true &&
							props.values[FormConstants.postcode] === ""
						) {
							props.setFieldValue(FormConstants.postcode, REPLACE);
							props.setFieldTouched(FormConstants.postcode, true, true);
						}
						if (data?.State === true && props.values[FormConstants.state] === "") {
							props.setFieldValue(FormConstants.state, REPLACE);
							props.setFieldTouched(FormConstants.state, true, true);
						}
						if (data?.Suburb === true && props.values[FormConstants.suburb] === "") {
							props.setFieldValue(FormConstants.suburb, REPLACE);
							props.setFieldTouched(FormConstants.suburb, true, true);
						}
						props.setDonorData(data);
					} else if (props.autoCompleted === true) {
						props.setAutoCompleted(false);
					}
				}
			);
		};
		if (
			props.values[FormConstants.email] !== "" &&
			props.values[FormConstants.email] !== undefined &&
			!props.errors[FormConstants.email] &&
			props.findDonorByEmailaddress
		) {
			setIsFetchingDonorData(true);
			fetchDonorData();
		}
	}, [debouncedEmailAddress]);

	useEffect(() => {
		if (props.autoCompleted) {
			props.setAutoCompleted(false);
			props.setFieldValue(FormConstants.address, "");
			props.setFieldValue(FormConstants.firstName, "");
			props.setFieldValue(FormConstants.lastName, "");
			props.setFieldValue(FormConstants.phone, "");
			props.setFieldValue(FormConstants.postcode, "");
			props.setFieldValue(FormConstants.state, "");
			props.setFieldValue(FormConstants.suburb, "");
		}
	}, [props.values[FormConstants.email]]);

	const currencySign = createCurrencySign(props?.defaultCurrency);

	const numberMask = createNumberMask({
		prefix: currencySign,
		integerLimit: 6,
		allowDecimal: true,
	});
	let shareUrl = props.shareUrl !== "" ? props.shareUrl : location.href;

	const amountSelected =
		props.values[FormConstants.amount] !== undefined &&
		props.values[FormConstants.amount] !== null &&
		props.values[FormConstants.amount] > 0;

	const fieldsToCheck = [
		...(props.enableCountryField ? stepOneAllFields : stepOneStandardFields),
		...props.customFields?.map((field) => field.Name),
	];
	const allFieldsFilledOut =
		!fieldsToCheck.some((field) => props.errors[field]) &&
		fieldsToCheck.every(
			(field) =>
				props.values[field] !== undefined &&
				props.values[field] !== null &&
				props.values[field] !== ""
		);
	return (
		<Form>
			<div
				className={
					`${props.disableGradient ? "" : "donGradient"} donForm ` + props.colorTheme
				}
				style={getStyle()}
			>
				{props.stepNum !== 3 ? (
					<>
						<div>
							<p
								className={
									`${
										props.singlePageForm
											? "donFormHeaderSinglePage"
											: "donFormHeader"
									} donOpposite` + props.colorTheme
								}
							>
								{props.formHeader}
							</p>
							{props.formHeaderSubtext && (
								<p
									className={
										"donFormHeaderSubtext donOpposite" + props.colorTheme
									}
								>
									{props.formHeaderSubtext}
								</p>
							)}
							<hr className="donLine" />
							<div className="rootContainerThing" style={{ marginTop: "20px" }}>
								<div className="container">
									<div
										className={
											props.narrowForm || props.fieldsAsColumn
												? "donSinglePageAmounts col-md-12 my-1 px-2"
												: "donSinglePageAmounts"
										}
									>
										{props.donateAmounts
											.filter((d: number, i: number) => i <= 4)
											.map((donateAmount: number, index: number) => {
												return (
													<div
														key={index}
														className="amountButtonWrapper"
													>
														<button
															className={`donAmountButton ${
																props.colorTheme
															}button${
																donateAmount ===
																props.values[FormConstants.amount]
																	? "Selected"
																	: ""
															} ${
																props.buttonRoundedCorners
																	? "donButtonRoundedCorners"
																	: ""
															}`}
															type="button"
															key={index}
															onClick={() =>
																props.setFieldValue(
																	FormConstants.amount,
																	donateAmount
																)
															}
														>
															{currencySign}
															{donateAmount}
														</button>
													</div>
												);
											})}

										<Field
											name="amount"
											render={(field: any) => {
												return (
													<div className="donFieldAmountWrapper">
														<MaskedInput
															{...field}
															value={
																props.values[FormConstants.amount]
															}
															onChange={(e) =>
																props.setFieldValue(
																	FormConstants.amount,
																	formatNumber(e.target.value)
																)
															}
															className={`donFieldAmount ${
																props.values[
																	FormConstants.amount
																] &&
																!props.donateAmounts.find(
																	(a) =>
																		a ===
																		props.values[
																			FormConstants.amount
																		]
																)
																	? props.colorTheme +
																	  "buttonSelected"
																	: "donFieldAmount" +
																	  props.colorTheme
															} ${
																props.buttonRoundedCorners
																	? "donButtonRoundedCorners"
																	: ""
															}`}
															mask={numberMask}
															guide={false}
															placeholder="Other"
															style={{
																height: "inherit",
																fontWeight: "bolder",
															}}
														/>
													</div>
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row" style={{ justifyContent: "center" }}>
								<Field
									className={"donFrequency donOpposite" + props.colorTheme}
									component="div"
									name={FormConstants.interval}
									onChange={(c) =>
										props.setFieldValue(
											FormConstants.interval,
											c.target.checked === true ? "month" : "one"
										)
									}
								>
									<div className="donRadioWrapper form-check">
										<input
											style={{ cursor: "pointer" }}
											defaultChecked={
												props.values[FormConstants.interval] === "month"
											}
											name={FormConstants.interval}
											type="checkbox"
											className="form-check-input"
											id="month"
										/>
										<label
											style={{ cursor: "pointer" }}
											className="form-check-label"
											htmlFor="month"
										>
											Make my donation monthly
										</label>
									</div>
								</Field>
							</div>
						</div>
						{amountSelected && (
							<>
								<div className="container singlePageSection">
									{props.findDonorByEmailaddress && (
										<div className="row">
											<StepOneFieldFactory
												fieldName={FormConstants.email}
												{...props}
												isColumn={true}
											/>
											{props.autoCompleted && (
												<p
													className={
														"col-md-12 donTaxMessage donOpposite" +
														props.colorTheme
													}
													style={{ marginBottom: 0, marginTop: 10 }}
												>
													Welcome back! If your address or phone number
													has changed since the last time you donated,{" "}
													<label
														style={{
															textDecoration: "underline",
															cursor: "pointer",
														}}
														onClick={() => {
															props.setAutoCompleted(false);
															props.setFieldValue(
																FormConstants.address,
																""
															);
															props.setFieldValue(
																FormConstants.firstName,
																""
															);
															props.setFieldValue(
																FormConstants.lastName,
																""
															);
															props.setFieldValue(
																FormConstants.phone,
																""
															);
															props.setFieldValue(
																FormConstants.postcode,
																""
															);
															props.setFieldValue(
																FormConstants.state,
																""
															);
															props.setFieldValue(
																FormConstants.suburb,
																""
															);
														}}
													>
														click here to update them.
													</label>
												</p>
											)}
										</div>
									)}
									<LoadingOverlay
										active={isFetchingDonorData}
										className={"loadingOverlay"}
										spinner={
											<ClipLoader sizeUnit={"px"} size={20} color={"white"} />
										}
									>
										<div className="row">
											{stepOneAllFields
												.filter((field) => {
													if (
														props.findDonorByEmailaddress &&
														field === FormConstants.email
													) {
														return false;
													} else if (props.autoCompleted) {
														if (
															props.donorData &&
															props.donorData?.[field] === false
														) {
															return true;
														} else {
															return false;
														}
													} else {
														return true;
													}
												})
												.map((f, i) => (
													<StepOneFieldFactory
														key={i}
														fieldName={f}
														{...props}
														isColumn={
															props.fieldsAsColumn || props.narrowForm
														}
													/>
												))}
										</div>
									</LoadingOverlay>
									<div className="row">
										{props?.customFields?.map((f, i) => {
											return CustomFormFieldFactory(
												f,
												props.fieldsAsColumn || props.narrowForm,
												i,
												props.errors,
												props.touched,
												props.colorTheme,
												props.setFieldValue
											);
										})}
									</div>
								</div>
								{props.campaigns && props.campaigns.length > 0 && (
									<div className="singlePageSection">
										<p className={"donCauseText"}>
											I wish to donate to the following campaign:
										</p>
										<FormField
											colorTheme={props.colorTheme}
											isColumn={props.fieldsAsColumn || props.narrowForm}
											type="select"
											className="form-control standard-form-group"
											name={FormConstants.donationRecipient}
											component="select"
											placeholder="Campagin*"
											errors={props.errors}
											touched={props.touched}
											children={props.campaigns}
										/>
									</div>
								)}
							</>
						)}
						{allFieldsFilledOut && (
							<div className="singlePageSection">
								<p
									className={"donTaxMessage donOpposite" + props.colorTheme}
									style={{ marginBottom: 0, marginTop: 10 }}
								>
									Contributions are tax deductible.
								</p>
								<div className="row justify-content-center">
									<CheckoutFactory
										provider={props.paymentProvider}
										setComplete={props.setPaymentInputComplete}
										colorTheme={props.colorTheme}
										values={props.values}
										contentId={props.contentId}
										setSubmitError={props.setSubmitError}
										stripe={props.stripe}
										updateTokenGenerator={props.updateTokenGenerator}
										setExpiryMonth={props.setExpiryMonth}
										setExpiryYear={props.setExpiryYear}
									/>
								</div>
								{props.values[FormConstants.amount] &&
									props.paypalClientId !== undefined &&
									props.paypalClientId !== "" && (
										<PaypalButton
											contendId={props.contentId}
											colorTheme={props.colorTheme}
											paymentProvider={props.paymentProvider}
											setFieldValue={props.setFieldValue}
											setSubmitError={props.setSubmitError}
											submitForm={props.submitForm}
											values={props.values}
											paypalClientId={props.paypalClientId}
											isSubmitting={props.isSubmitting}
										/>
									)}
							</div>
						)}

						<div className="donProgressArea container">
							<div className="donSubmitErrorMessage">{props.submitError}</div>
							<div className="donErrorMessages">
								{Object.keys(props.values).map((key, index) => {
									if (props.touched[key] && props.errors[key]) {
										return (
											<ErrorMessage
												errors={props.errors}
												touched={props.touched}
												colorTheme={props.colorTheme}
												name={key}
												key={index}
											/>
										);
									}
								})}
							</div>
							{props.donationDeclaration && allFieldsFilledOut ? (
								<div className="container">
									<div
										style={{ textAlign: "center" }}
										className={`checkbox-group-wrapper my-2 px-4 col-md-12 ${
											props.touched[FormConstants.donationDeclaration] &&
											props.errors[FormConstants.donationDeclaration]
												? "error-validation-custom error-validation-" +
												  props.colorTheme
												: ""
										}`}
									>
										<Checkbox name={FormConstants.donationDeclaration}>
											{props.donationDeclaration}
										</Checkbox>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="progress-bar-buttons">
								<button
									className={`donButton ${props.colorTheme}ContSubButton ${
										props.submitClass
									} ${
										props.buttonRoundedCorners ? "donButtonRoundedCorners" : ""
									}`}
									type="submit"
									disabled={props.submitDisabled || !allFieldsFilledOut}
								>
									Donate
								</button>
							</div>
						</div>

						{props.formImageUrl && !props.narrowForm && (
							<div className="donImageWrapper">
								<img
									className="donImage img-fluid p-0"
									src={props.formImageUrl}
								></img>
							</div>
						)}
					</>
				) : (
					<>
						<div className="container singlePageSection" style={{ marginBottom: 20 }}>
							<div className="justify-content-center">
								<h1 className={"donFormHeader donOpposite" + props.colorTheme}>
									Thank you for your donation.
								</h1>
								<p className={"donThankYou donOpposite" + props.colorTheme}>
									{props.tYMessage}
								</p>
							</div>
							<div>
								<h1 className={"donFormHeader donOpposite" + props.colorTheme}>
									{createCurrencySign(props?.defaultCurrency)}
									{props.values[FormConstants.amount] &&
										Number(props.values[FormConstants.amount])}
								</h1>
							</div>
							<div className="donShareButtons">
								<div className="donShareButton">
									<FacebookShareButton
										url={shareUrl}
										quote={props.shareMessage}
										hashtag={props.shareHashtag}
									>
										<FacebookIcon round size={30} />
									</FacebookShareButton>
								</div>
								<div className="donShareButton">
									<TwitterShareButton
										url={shareUrl}
										hashtags={[props.shareHashtag]}
									>
										<TwitterIcon round size={30} />
									</TwitterShareButton>
								</div>
								<div className="donShareButton">
									<WhatsappShareButton url={shareUrl} title={props.shareTitle}>
										<WhatsappIcon round size={30} />
									</WhatsappShareButton>
								</div>
								<div className="donShareButton">
									<LinkedinShareButton url={shareUrl}>
										<LinkedinIcon round size={30} />
									</LinkedinShareButton>
								</div>
								<div className="donShareButton">
									<RedditShareButton url={shareUrl} title={props.shareTitle}>
										<RedditIcon round size={30} />
									</RedditShareButton>
								</div>
								<div className="donShareButton">
									<EmailShareButton
										url={shareUrl}
										subject={props.shareTitle}
										body={props.shareMessage}
									>
										<EmailIcon round size={30} />
									</EmailShareButton>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</Form>
	);
};

export default SinglePageInnerForm;
