import * as React from "react";

interface IProgressBarProps {
	stepNumber: number;
	colorTheme: string;
	stepAmount?: number;
}
export default function ProgressBar(props: IProgressBarProps) {
	const amount = [];

	const maxSteps = props.stepAmount ? props.stepAmount : 3;

	for (let i = 0; i < maxSteps - 1; i++) {
		amount.push(i);
	}

	const setProgress = (step: number, stepNum: number) => {
		if (step === stepNum) {
			return (
				"step backgroundOpposite" +
				props.colorTheme +
				" donJust" +
				props.colorTheme +
				" borderOpposite" +
				props.colorTheme
			);
		} else if (step < stepNum) {
			return (
				"step backgroundOpposite" +
				props.colorTheme +
				" donJust" +
				props.colorTheme +
				" borderOpposite" +
				props.colorTheme
			);
		} else {
			return "step donJust" + props.colorTheme + " borderOpposite" + props.colorTheme;
		}
	};
	const setProgressBar = (step: number, stepNum: number) => {
		if (step >= stepNum) {
			return "bar borderProgressBar" + props.colorTheme;
		} else {
			return (
				"bar backgroundOpposite" +
				props.colorTheme +
				" borderProgressBar" +
				props.colorTheme
			);
		}
	};
	return (
		<div className="donSteps">
			{amount.map((s, i) => {
				return (
					<div key={i} style={{ display: "flex" }}>
						<div className={setProgress(i, props.stepNumber)}></div>
						<hr className={setProgressBar(i, props.stepNumber)} />
					</div>
				);
			})}

			<div className={setProgress(amount.length, props.stepNumber)}></div>
		</div>
	);
}
