import * as React from "react";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon,
    LinkedinShareButton,
    LinkedinIcon,
    RedditShareButton,
    RedditIcon
} from "react-share";
import { createCurrencySign } from "./Step0";

interface IStep3Props {
    amount: number;
    tYMessage: string;
    shareUrl: string;
    shareTitle: string;
    shareMessage: string;
    shareHashTag: string;
    colorTheme: string;
    isColumn: boolean;
    currency: string
}

const DonateFormStepThree: React.FC<IStep3Props> = props => {
    let shareUrl = props.shareUrl !== "" ? props.shareUrl : location.href;
    return (
        <div className="container step03">
            <div className="justify-content-center">
                <h1 className={"donFormHeader donOpposite" + props.colorTheme}>Thank you for your donation.</h1>
                <p className={"donThankYou donOpposite" + props.colorTheme}>{props.tYMessage}</p>
            </div>
            <div>
                <h1 className={"donFormHeader donOpposite" + props.colorTheme}>{createCurrencySign(props?.currency)}{props.amount}</h1>
            </div>
            <div className="donShareButtons">
                <div className="donShareButton">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={props.shareMessage}
                        hashtag={props.shareHashTag}
                    >
                        <FacebookIcon round size={30} />
                    </FacebookShareButton>
                </div>
                <div className="donShareButton">
                    <TwitterShareButton
                        url={shareUrl}
                        hashtags={[props.shareHashTag]}
                    >
                        <TwitterIcon round size={30} />
                    </TwitterShareButton>
                </div>
                <div className="donShareButton">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={props.shareTitle}
                    >
                        <WhatsappIcon round size={30} />
                    </WhatsappShareButton>
                </div>
                <div className="donShareButton">
                    <LinkedinShareButton
                        url={shareUrl}
                    >
                        <LinkedinIcon round size={30} />
                    </LinkedinShareButton>
                </div>
                <div className="donShareButton">
                    <RedditShareButton
                        url={shareUrl}
                        title={props.shareTitle}
                    >
                        <RedditIcon round size={30} />
                    </RedditShareButton>
                </div>
                <div className="donShareButton">
                    <EmailShareButton
                        url={shareUrl}
                        subject={props.shareTitle}
                        body={props.shareMessage}
                    >
                        <EmailIcon round size={30} />
                    </EmailShareButton>
                </div>
            </div>
        </div >
    );
}

export default DonateFormStepThree