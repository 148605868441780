import * as React from "react";
import { Field, FormikErrors, FormikTouched } from "formik";
import { FormConstants } from "../../GenericForm/Components/Constants";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";
import Checkbox from 'formik-antd/es/checkbox';
import Radio from "formik-antd/es/radio";
import Select from "formik-antd/es/select"
import { IFieldProps } from "../../GenericForm/Components/FormFields";
import { removeWhiteSpaces } from "../../GenericForm/Components/FunctionLib";
import TimePicker from "formik-antd/es/time-picker";
import "formik-antd/es/checkbox/style/css"
import "formik-antd/es/radio/style/css";
import "formik-antd/es/select/style/css"
import "formik-antd/es/date-picker/style/css"
import { CustomFieldType } from "../../GenericForm/Components/FormField";
import DatePicker from "antd/lib/date-picker";

const CheckboxGroup = Checkbox.Group
const RadioGroup = Radio.Group
const {Option} = Select;

interface IFormFieldProps {
  name: string;
  className: string;
  placeholder: string;
  component?: string;
  type?: string;
  errors: any;
  touched: any;
  colorTheme: string;
  children?: string | JSX.Element | JSX.Element[];
  isColumn: boolean
  value?: React.ReactNode
  as?: React.ReactNode | JSX.Element | JSX.Element[]
}

const FormField: React.FC<IFormFieldProps> = (props) => {
  const getClassName = () => {
    if (props.isColumn) {
      return "col-md-12 my-1 px-3"
    } else {
    if (props.name === FormConstants.donationRecipient) {
        return "col-md-6 my-2 donCause"
      } else {
        return "col-md-6 my-2"
      }
    }
  }
  return (
    <div className={getClassName()}>
      <Field
        className={`${props.className} no-border ${props.errors[props.name] && props.touched[props.name]
          ? "error-validation-" + props.colorTheme
          : null
          }`}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        component={props.component}
        as={props.as}
      >
        {props.children}
      </Field>
    </div>
  );
}

const CustomFieldWrapper = (props: React.PropsWithChildren<ICustomFieldWrapperProps>) => {
  const { fieldProps, errors, touched, isColumn, className, labelClassName, colorTheme } = props
  return <div className={`${className} ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn, colorTheme)}`}>
      <p className={`custom-field-label ${labelClassName}`}>{!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : fieldProps.Name}{fieldProps.Required ? "*" : ""}</p>
      {props.children}
  </div>
}

const getCustomFieldSpacingClassName = (fieldProps: IFieldProps, errors: FormikErrors<any>, touched: FormikTouched<any>, isColumn: boolean, colorTheme: string) => {
  let className = "my-2 "
  className += isColumn ? "px-4 " : "px-3 ";
  className += fieldProps.IsFullWidth || isColumn ? "col-md-12 " : "col-md-6 "
  className += touched[fieldProps.Name] && errors[fieldProps.Name] ? "error-validation-custom error-validation-" + colorTheme : ""
  return className;
}


export const CustomFormFieldFactory = (fieldProps: IFieldProps, isColumn: boolean, key: string | number, errors: FormikErrors<any>, touched: FormikTouched<any>, colorTheme: string, setFieldValue: any) => {
  switch (fieldProps.CustomFieldType) {
      case CustomFieldType.Checkbox:
          return <div key={key} className={`checkbox-group-wrapper ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn, colorTheme)}`}>
              <Checkbox name={fieldProps.Name}>{!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : fieldProps.Name}{fieldProps.Required ? "*" : ""}</Checkbox>
          </div>

      case CustomFieldType.CheckboxGroup:
          return <CustomFieldWrapper className="checkbox-group-wrapper" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn} colorTheme={colorTheme}>
              <CheckboxGroup name={fieldProps.Name} options={fieldProps.Options} />
          </CustomFieldWrapper>
      
      case CustomFieldType.RadioButtonGroup:
          return <CustomFieldWrapper className="radio-group-wrapper" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn} colorTheme={colorTheme}>
              <RadioGroup name={fieldProps.Name} options={fieldProps.Options} />
          </CustomFieldWrapper>
      
      case CustomFieldType.DropDown:
          return <Select key={key} name={fieldProps.Name}
              dropdownClassName="custom-select-dropdown"
              placeholder={`${!isNullOrWhiteSpace(fieldProps.Label) ? fieldProps.Label : `Please select the ${fieldProps.Name}`} ${fieldProps.Required ? "*" : ""}`}
              className={`custom-select-field ${getCustomFieldSpacingClassName(fieldProps, errors, touched, isColumn, colorTheme)}`}>
              {fieldProps.Options && fieldProps.Options.length > 0 && fieldProps.Options.map((option, i) =>
                  <Option key={i} value={option}>{option}</Option>)}
          </Select>

      case CustomFieldType.DatePicker:
          return <CustomFieldWrapper className="date-time-picker-wrapper d-flex" labelClassName="col-sm-6" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn} colorTheme={colorTheme}>
              <DatePicker onChange={(value) => setFieldValue(fieldProps.Name, value)}  className="col-sm-6" format='DD/MM/YYYY' name={fieldProps.Name} />
          </CustomFieldWrapper>

      case CustomFieldType.TimePicker:
          return <CustomFieldWrapper className="date-time-picker-wrapper d-flex" labelClassName="col-sm-6" key={key} fieldProps={fieldProps} touched={touched} errors={errors} isColumn={isColumn} colorTheme={colorTheme}>
              <TimePicker className="col-sm-6" format='hh:mm a' name={fieldProps.Name} />
          </CustomFieldWrapper>

      case CustomFieldType.Text:
      default:
          return <FormField
          isColumn={isColumn || fieldProps.IsFullWidth}
          key={key}
          className="form-control"
          name={removeWhiteSpaces(
              fieldProps.Name
          )}
          placeholder={
              fieldProps.Required
                  ? fieldProps.Placeholder +
                  "*"
                  : fieldProps.Placeholder
          }
          component={
              fieldProps.Textarea
                  ? "textarea"
                  : undefined
          }
          errors={errors}
          touched={touched}
          colorTheme={colorTheme}
      />
  }
}

interface ICustomFieldWrapperProps {
  className?: string
  fieldProps: IFieldProps
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  key: string | number
  isColumn: boolean
  labelClassName?: string
  colorTheme: string
}

export default FormField
