import * as React from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { FormConstants } from "../../GenericForm/Components/Constants";
import { formatNumber } from "../../GenericForm/Components/FunctionLib";
import { paymentInterval } from "./Step2";

interface IStep0Props {
    animClass: string;
    values: React.ReactNode;
    setFieldValue: (value: string, formattedValue: string) => void;
    donateAmounts: number[];
    onClick: (donateAmount: number, setFunc: (value: string, formattedValue: string) => void) => void;
    colorTheme: string;
    isColumn: boolean;
    buttonRoundedCorners: boolean;
    currency: string
    hideOtherAmount: boolean
    recurringDonationOptionsOnFirstPanel: boolean;
    intervalOptions?: paymentInterval[];
}

export const createCurrencySign = (currency: string) => {
  switch (currency?.toUpperCase()) {
    case "AUD":
    default:
      return "$"
    case "GBP":
      return "£"
  }
}

const intervalOptionToValue = {
  ["One-off"]: "one",
  ["Weekly"]: "week",
  ["Monthly"]: "month",
  ["one"]: "One-off",
  ["week"]: "Weekly",
  ["month"]: "Monthly"
};
export default (props: IStep0Props) => {
  const currencySign = createCurrencySign(props?.currency)

  const numberMask = createNumberMask({
    prefix: currencySign,
    integerLimit: 6,
    allowDecimal: true
  });

  const numOfRows = props.isColumn ? props.donateAmounts?.length : Math.ceil(props.donateAmounts?.length / 2)

  const buttonGridStyle: React.CSSProperties = {
    gridTemplateRows: `repeat(${numOfRows}, 40px)`
  }
  const shallOtherAmountPlaceHasLabel = props.isColumn || props.donateAmounts?.length == 1 || (!props.isColumn && Math.ceil(props.donateAmounts?.length) % 2 == 0)
  const otherAmountPlaceHolderTxt = shallOtherAmountPlaceHasLabel ? "Enter Amount" : "Other Amount"
  
  const buttonIntervalOptions = [...props.intervalOptions]
  if(buttonIntervalOptions.includes("One-off")) {
    const index = buttonIntervalOptions.findIndex(o => o === "One-off");
    if(index >= 0) {
      const el = buttonIntervalOptions[index];
      buttonIntervalOptions.splice(index, 1);
      buttonIntervalOptions.splice(0, 0, el)
    }
  }

  return (
    <div className="container">
      {props.recurringDonationOptionsOnFirstPanel && 
        <div className="donIntervalOptions">{buttonIntervalOptions?.map((option) => {
            return <button onClick={() => props.setFieldValue(FormConstants.interval, intervalOptionToValue[option])} className={`donAmountButton ${props.colorTheme}button ${props.values[FormConstants.interval] === intervalOptionToValue[option] ? `${props.colorTheme}buttonSelected` : ''}`} type="button" style={{ fontWeight: "bolder", textAlign: "center", height: '100%',marginLeft: 5, marginRight: 5  }}>{props.values[FormConstants.interval] === intervalOptionToValue[option] ?  <span>&#10004;</span> : ''} {option?.toUpperCase()}</button>
          })}
        </div>}
      <div className={props.isColumn || props.donateAmounts?.length == 1 ? "donAmountColumnButtons" : "donAmountRowButtons"} style={buttonGridStyle}>
        {props.donateAmounts?.length == 1 ? <div style={{ fontSize: "3.5rem", fontWeight: "bolder", textAlign: "center" }}>{currencySign}{props.donateAmounts[0]} </div>
          : props.donateAmounts.map(
            (donateAmount: number, index: number) => {
              return (
                <div key={index} className="amountButtonWrapper">
                  <button
                    className={`donAmountButton ${props.colorTheme}button ${props.buttonRoundedCorners ? 'donButtonRoundedCorners' : ''}`}
                    type="button"
                    key={index}
                    onClick={() =>
                      props.onClick(
                        donateAmount,
                        props.setFieldValue
                      )
                    }
                  >
                    {currencySign}{donateAmount}
                  </button>
                </div>
              );
            }
          )}

        {!props.hideOtherAmount ? <>
          {shallOtherAmountPlaceHasLabel ? <label className={"donLabelAmount donOpposite" + props.colorTheme} htmlFor="donFieldAmount">
                        Other amount
          </label> : null}
          <Field
            style={!shallOtherAmountPlaceHasLabel ? { height: "40px" } : null}
            name="amount"
            render={(field: any) => {
              return (
                <div className="donFieldAmountWrapper">
                  <MaskedInput
                    {...field}
                    value={
                      props.values[FormConstants.amount]
                    }
                    onChange={e =>
                      props.setFieldValue(
                        FormConstants.amount,
                        formatNumber(e.target.value)
                      )
                    }
                    className={`donFieldAmount donFieldAmount${props.colorTheme} ${props.buttonRoundedCorners && !shallOtherAmountPlaceHasLabel ? 'donButtonRoundedCorners' : ''}`}
                    mask={numberMask}
                    guide={false}
                    placeholder={otherAmountPlaceHolderTxt}
                  />
                </div>
              );
            }}
          /></> : null
        }
      </div>
    </div>
  );
}
